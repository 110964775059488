<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('base_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="450"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="100" :rules="rules">
        <FormItem label="基地名称" prop="name">
          <Input
            clearable
            placeholder="请输入基地名称"
            v-model="form.name"
          ></Input>
        </FormItem>
        <FormItem label="养殖面积" prop="breedArea">
          <Input
            clearable
            placeholder="请输入养殖面积"
            v-model="form.breedArea"
          ></Input>
        </FormItem>
        <FormItem label="种植面积" prop="plantArea">
          <Input
            clearable
            placeholder="请输入种植面积"
            v-model="form.plantArea"
          ></Input>
        </FormItem>
        <FormItem label="负责人" prop="chargePerson">
          <Input
            clearable
            placeholder="请输入负责人"
            v-model="form.chargePerson"
          ></Input>
        </FormItem>
        <FormItem label="负责人电话" prop="chargePersonTel">
          <Input
            clearable
            placeholder="请输入负责人电话"
            v-model="form.chargePersonTel"
          ></Input>
        </FormItem>
        <FormItem label="详细地址" prop="address">
          <Input
            clearable
            placeholder="请输入详细地址"
            v-model="form.address"
          ></Input>
        </FormItem>
        <FormItem label="基地绘制" prop="mapPosition">
          <Input
            placeholder="点击右侧绘制基地地图"
            v-model="form.mapPosition"
            disabled
          >
            <span
              slot="append"
              class="iconfont"
              style="cursor: pointer"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="省市区编码" prop="regionCode">
          <Input
            clearable
            placeholder="请输入省市区编码"
            v-model="form.regionCode"
          ></Input>
        </FormItem>
        <FormItem label="基地类型" prop="siteType">
          <Select v-model="form.siteType" placeholder="请选择基地类型">
            <Option :value="1">种植</Option>
            <Option :value="2">养殖</Option>
          </Select>
        </FormItem>
        <FormItem label="备注">
          <Input
            type="textarea"
            clearable
            placeholder="请输入备注"
            v-model="form.remark"
            :autosize="{ minRows: 3 }"
          ></Input>
        </FormItem>
        <FormItem label="上传图片">
          <upload-image
            v-model="form.imgUrl"
            :max="1"
            :clear-flag="!modal.show"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :mapPosition="form.mapLocation"
    ></draw-map-modal>
  </div>
</template>

<script>
import drawMapModal from "./drawMapModal";
export default {
  name: "",
  components: {
    drawMapModal,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "基地名称",
            key: "name",
            minWidth: 160,
          },
          {
            title: "负责人",
            width: 150,
            key: "chargePerson",
          },
          {
            title: "负责人电话",
            width: 150,
            key: "chargePersonTel",
          },
          {
            title: "基地地址",
            width: 260,
            key: "address",
          },
          {
            title: "基地类型",
            width: 180,
            render: (h, { row }) => {
              return <span>{row.siteType == 1 ? "种植基地" : "养殖基地"}</span>;
            },
          },
          {
            title: "创建时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("base_edit") && (
                    <a
                      style="margin-right:20px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("base_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增基地",
            ca: "base_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "基地名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      drawMapModalShow: false,
      form: {
        id: "",
        name: "",
        breedArea: "",
        plantArea: "",
        productContent: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "",
        regionCode: "",
        mapPosition: "",
        thirdLongLat: "",
      },
      rules: {
        name: [{ required: true, message: "请填写基地名称" }],
        breedArea: [{ required: true, message: "请填写养殖面积" }],
        plantArea: [{ required: true, message: "请填写种植面积" }],
        chargePerson: [{ required: true, message: "请填写负责人" }],
        chargePersonTel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写养殖地址" }],
        regionCode: [{ required: true, message: "请填写省市区编码" }],
        siteType: [{ required: true, message: "请选择基地类型" }],
        mapPosition: [{ required: true, message: "请绘制基地地图" }],
      },
    };
  },
  methods: {
    getList() {
      if (!this.CA("base_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    search(value) {
      this.search_data = value;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "新增基地",
        submitLoading: false,
      };
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      if (row.imgUrl == "imageundefined") {
        this.form.imgUrl = "";
      } else {
        this.form.imgUrl = row.servicePath + row.imgUrl;
      }
      this.modal = {
        show: true,
        title: "编辑基地",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.BASE_MANAGE.UPDATE;
        } else {
          url = this.$api.BASE_MANAGE.ADD;
        }
        params.imgUrl = "image" + this.form.imgUrl.split("image")[1];
        if (!res) return;
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    drawMap() {
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data[0]);
      this.form.thirdLongLat = data[1].toString();
      this.drawMapModalShow = false;
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        breedArea: "",
        plantArea: "",
        productContent: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "",
        regionCode: "",
        thirdLongLat: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>